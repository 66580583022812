import React from 'react';
import { Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const SecondPage = () => (
  <Layout>
    <Container>
      <h1>End User License Agreement (EULA)</h1>
      <code>

        <br />  1. Scope of the Agreement
        <br />  2. Account Registration
        <br />  3. Orders
        <br />  4. Grant of License
        <br />  5. Evaluation License
        <br />  6. Third-Party Software
        <br />  7. Price and Payment
        <br />  7.1. Support Services
        <br />  8. Termination
        <br />  9. Copyright
        <br />  10. Confidentiality
        <br />  11. Disclaimer of Warranties
        <br />  12. Return Policy
        <br />  13. Infringement Indemnification
        <br />  14. Limitation of Liability
        <br />  15. Dispute Resolution
        <br />  16. Severability clause
        <br />  17. No Waiver
        <br />  18. Assignment
        <br />  19. Revisions to this EULA
        <br />  20. Entire Agreement
        <br />  21. Contact Details

        <br />
        Be sure to carefully read and understand all of the rights and restrictions mentioned in this end user license agreement (“EULA”).

        By installing or using any part of the app (hereinafter called software), you and the entity or company that you represent ("you") are unconditionally consenting to be bound by and are becoming a party to this license agreement ("agreement"). if you do not unconditionally agree to all of the terms of this agreement, you will not have any right to use the software

        This EULA is a binding legal agreement between Schnell Cloud (hereinafter “Licensor”), a provider of downloadable and cloud-based applications through the Freshworks Marketplace or any other means that inter-operate with applicable products manufactured by Freshworks Inc (“Freshworks”), and you (either an individual or a single legal entity you represent) whose details are provided to Licensor upon purchase (hereinafter “Licensee” or “you”) for the materials accompanying this EULA, including the accompanying computer software, associated media, printed materials and any “online” or electronic documentation.

        By clicking on the “I agree” (or similar button) that is presented to you at the time of your order, or by installing, copying, downloading or otherwise using the Software, you agree to be bound by the terms of this EULA. If you do not agree to the terms of this EULA, you may not install, copy, download or otherwise use the Software. If you are agreeing to this EULA on behalf of a company or other organization, you represent that you have the authority to bind that company or organization to this EULA, and the terms Licensee, "you" and “your" refer to that company or organization. If you do not have that authority, you may not install, copy, download or otherwise use the Software.

        This is a “Publisher EULA” as referred to in the Freshworks Marketplace Terms of Use, found at https://www.freshworks.com/terms/.

        <br />1. Scope of the Agreement
        A. This EULA governs (a) Licensor’s commercially available downloadable software products sold, or made available at no charge (“Software”), (b) Licensor’s Software provided in a hosted or cloud-based environment (“Hosted Services”), and (c) any support services provided by Licensor relating to the software or hosted Services. Software and Hosted Services, together with related Documentation, are referred to herein as “Products”.

        B. This EULA does not cover the sale or resale of Freshworks-manufactured software, Licensor’s other professional services relating to Freshworks-manufactured software. Additionally it does not apply to the source code provided via the repositories - for the source code the Source code license agreement applies.

        <br /> 2. Account Registration
        You may need to register on the Freshworks Marketplace at https://www.freshworks.com/apps/ in order to place orders or access or receive any products. Any registration information that you provide must be accurate, current and complete. You must also update your information so that Licensor may send notices, statements and other information to you by email or through your account. You are responsible for all actions taken through your accounts.

        <br /> 3. Orders
        Your order through the Freshworks Marketplace or with an authorized Reseller (“Order”) will specify your authorized scope of use for the products, which may include: (a) the defined number of installations, the number of specific individuals for whom you have paid the required fees and whom you designate through the applicable Product (“Authorized Users”), the number of authorized servers, the number of unique data set platforms, and/or other defined resource utilization limitations, (b) storage or capacity (for Hosted Services), (c) numbers of licenses, copies or instances (for Software), or (d) other restrictions or billable units (all of the above, as applicable, the “Scope of Use”). The term “Order” also includes any applicable Product or Support Services renewal, or purchases you make to increase or upgrade your scope of Use. You may increase the number of Authorized Users permitted to access your instance of the Product by placing a new "Order" or, in some cases, directly through the product.

        <br /> 4. Grant of License
        The products are licensed, not sold, and no ownership right is conveyed to you, irrespective of the use of terms in this EULA such as “purchase” or “sale”.

        a) This EULA grants you the following rights:

        Standard Use. For other than no-Charge products, the Licensor grants you a perpetual (subject to termination for breach), worldwide, non-exclusive, non-transferable,  not sub licensable license to install and use the software in object code only, limited to the scope of use as designated in your Order.

        Hosted Services. The Licensor grants you a monthly (paid in advance) subscription for worldwide, non-exclusive, non-transferable,  non sub-licensable use of the Hosted Services, subject to automatic renewal for successive monthly terms unless either Licensor or you notifies the other of non-renewal or Licensor ceases to make a particular Hosted Service available. If you cancel, your subscription will terminate at the end of the current billing cycle, but you will not be entitled to any credits or refunds for amounts accrued or paid prior to such termination. You acknowledge that Hosted Services are on-line, subscription-based products, hosted by Licensor and/or Freshworks, and that Licensor and/or Freshworks may make changes to the Hosted Services from time to time.

        No-Charge Products. The Licensor may offer you a time-limited, worldwide, non-exclusive, non-transferable, non sub-licensable limited license for certain Products at no charge. Licensor may terminate your right to use no-charge products at any time and for any reason in its sole discretion, without liability to you.

        b) Your license rights under this EULA are non-exclusive, non-transferable and non sub-licensable. You may not sell, transfer or convey the software to any third party without Licensor’s prior express written consent. Licensor reserves all rights not expressly granted to the Licensee in this EULA.

        c) Standard Use licensees are permitted to make one (1) copy of the software for data protection, archiving and backup purposes only and for no other purpose.

        d) You may only install the Software and make the Software available for use on hardware systems owned, leased or controlled by you, or your third party service providers so long as you remain responsible for their compliance with the terms and conditions of this EULA.

        e )This EULA applies whether you purchase Products directly from Licensor, through the Freshworks marketplace, through an authorized Reseller or otherwise. If you purchase through a Reseller, your license rights shall be as stated in the Order placed by Reseller for you, and the Reseller is responsible for the accuracy of any such Order. Resellers are not authorized to make any promises or commitments on Licensor’s behalf, and Licensor is not bound by any obligations to you other than what is included in this EULA.

        <br /> 6. Evaluation License
        Products may be provided for free evaluation period for you to assess its features and performance. Evaluation license is granted for evaluation purposes only, and for a limited period of time. Freshworks handles evaluations and sets out evaluation terms and conditions and its extension (if applicable).

        <br />  5. Third-Party Software
        You acknowledge the products may contain software licensed by Licensor from third parties, including open source software, and embedded in the Products, and that in addition to the obligations of this EULA, additional obligations may apply in relation to any use of the third party software by you which is not in accordance with the use of the Products as permitted under the terms of this EULA.  In such circumstances you must consult the relevant third party to acquire any necessary licenses and consents in relation to your use of the third party software.

        <br /> 6. Price and Payment
        If you have not previously paid the license fee for the Product, then you must pay the license fee within the period indicated in the applicable invoice or as otherwise provided in Licensor’s pricing terms as published on the Freshworks Marketplace. Failure to pay any license fees by the due date will result in the immediate termination of the license(s) granted under this EULA.

        <br />  7. Support Services
        a) Licensor may provide you with online support services related to the Products (“Support Services”), in its discretion and for the sole purpose of addressing technical issues relating to the use of the Products. Support Services also include access to bug fixes, patches, modifications, or enhancements (together, “Releases”) to the Products that Licensor makes generally commercially available during the “Support Period.” When accepted by you, any such Releases will be considered part of the Products and subject to the terms of this EULA.

        b) The Initial Support Period for paid Product is for twelve (12) months starting at the time each product is purchased, and may be renewed for additional twelve (12) month periods (each, a “Renewal Support Period”) at the current rate for support services. Renewal Support Periods commence upon the expiration of the prior Support Period regardless of when the product is purchased.

        c) Use of Support Services, if any, is governed by Licensor’s policies and programs described in any user manual, in online documentation, and/or other Licensor-provided materials. Any supplemental software code provided to you as a part of Support Services will be considered part of the Products and subject to the terms of this EULA.

        d) Free or trial versions of the Product are not supported by default
<br />
        8. Termination
        You may terminate your license to the Products at any time by destroying all your copies of the Software or ceasing your access to the Hosted Services. Your license to the Products shall automatically terminate if you fail to comply with the terms of this EULA. Upon termination of your license, you are required to remove all Software from your computer systems and destroy any copies of the Software in your possession.

        <br />9. Copyright
        a) The Products and all copies thereof are protected by copyright and other intellectual property laws and treaties. Licensor or its relevant third parties own the title, copyright, and all other intellectual property rights in the Products and all subsequent copies of the Products.

        b) All title and copyrights in and to the products (including but not limited to any images, scripts, templates), and any copies of the products, are owned by Licensor or its suppliers. This EULA does not grant you any rights to use such content. If the Products contain documentation that is provided only in electronic form, you may print one copy of such electronic documentation. Except for any copies of this EULA, you may not copy the printed materials accompanying the Products.

        c) Other than as allowed by this EULA, you may not (i) reverse engineer, de-compile, disassemble, alter, duplicate, modify, rent, lease, loan, sub-license, make copies of, create derivative works from, distribute or provide non-Authorized Users with access to the Products in whole or part, (ii) use the Products for the benefit of any third party, (iii) incorporate any Products into a product or service you provide to a third party, (iv) interfere with any license key mechanism in the Products or otherwise circumvent mechanisms in the Products intended to limit your use, (v) remove or obscure any proprietary notices on the products or any permitted copies of the products , or (vi) publicly disseminate information regarding the bench-marking performance of the Products.

        d) You may not copy or embed elements of the Source Code into other applications, or publish, transmit or communicate the Source Code to other parties other than yourself or the entity you represent.

        <br /> 10. Confidentiality
        You agree that all code, inventions, know-how, business, technical and financial information disclosed to you by Licensor constitute the confidential property of Licensor (“Confidential Information”). Any intellectual property, the underlying technology, and any performance information relating to the Products shall be deemed Confidential Information without any marking or further designation. Except as expressly authorized herein, you will hold in confidence and not use or disclose any Confidential Information. Your nondisclosure obligation shall not apply to information that you can document: (i) was rightfully in your possession or known to you prior to receipt of the Confidential Information; (ii) is or has become public knowledge through no fault of your own; (iii) is rightfully obtained by you from a third party without breach of any confidentiality obligation; or (iv) is independently developed by you or your employees who had no access to such information. You may also disclose Confidential Information if so required pursuant to a regulation, law or court order (but only to the minimum extent required to comply with such regulation or order and with advance notice to Licensor). You acknowledges that disclosure of Confidential Information would cause substantial harm for which damages alone would not be a sufficient remedy, and therefore that upon any such disclosure by you, Licensor shall be entitled to appropriate equitable relief in addition to whatever other remedies it might have at law. For the avoidance of doubt, this Section shall not operate as a separate warranty with respect to the operation of any Products.

        <br />  11. Disclaimer of Warranties
        Save as provided in Sections 14 and 15 below, the products are provided on an “as is” and “as available” basis without warranty, express or implied, of any kind or nature, including, but not limited to, any warranties of performance, merchantability, fitness for a particular purpose, or title. You may have other statutory rights, but the duration of statutorily required warranties, if any, shall be limited to the shortest period permitted by law. Licensor shall not be liable for delays, interruptions, service failures and other problems inherent in use of the internet and electronic communications or other systems outside the reasonable control of Licensor. To the maximum extent permitted by law, Licensor does not make any representation, warranty or guarantee that: (a) the use of the Products will be secure, timely, uninterrupted or error-free; (b) the Products will operate in combination with any other hardware, software, system, or data; (c) the Products will meet your requirements or expectations; (d) any stored data will be accurate or reliable or that any stored data will not be lost or corrupted; (e) errors or defects will be corrected; or (f) the Products (or any server(s) that make a Hosted Service available) are free of viruses or other harmful components.

        <br /> 12. Return Policy
        Licensor’s customary business practice is to allow customers to return Software within 30 days of payment for any reason or no reason and to receive a refund of the amount paid for the returned Software.  A return means that Licensor will disable the license key that allowed the Software to operate. Licensor will not accept returns after the 30-day return period. Returns are not available for Hosted Services.

        <br />13. Infringement Indemnification
        a) If you purchase a Standard Use license, and if the Software becomes, or in the opinion of Licensor may become, the subject of a claim of infringement of any third party right, Licensor may, at its option and in its discretion: (i) procure for Licensee the right to use the Software free of any liability; (ii) replace or modify the Software to make it non-infringing; or (iii) refund any license fees paid by you for the current Support Period for that Software.

        b) Licensee will defend or settle, at Licensee’s expense, any action brought against Licensor based upon the claim that any modifications to the Software or combination of the Software with other, third-party, products infringes or violates any third party right, and only to the extent that such modification or combination contributes to such claim; provided, however, that: (i) Licensor shall notify Licensee promptly in writing of any such claim; (ii) Licensor shall not enter into any settlement or compromise any such claim without Licensee’s prior written consent; (iii) Licensee shall have sole control of any such action and settlement negotiations; and (iv) Licensor shall provide Licensee with information and reasonable assistance, at Licensee’s request and expense, necessary to settle or defend such claim. Licensee agrees to pay all damages and costs finally awarded against Licensor attributable to such claim.

        c) Licensor assumes no liability hereunder for, and shall have no obligation to defend Licensee or to pay costs, damages or attorney’s fees for, any claim based upon any modifications to any of the Software not approved by Licensor or combination of any of the Software with products not approved by Licensor, and only to the extent that such modification or combination contributes to such claim.

        <br />  14. Limitation of Liability
        a) Except for the indemnification obligations of Section 13, neither party will be liable to any person, with respect to any loss, damage, cost, expense or other claim, for any consequential (such as loss of income; loss of business profits or contracts; business interruption; loss of the use of money or anticipated savings; loss of information; loss of opportunity, goodwill or reputation; loss of, damage to or corruption of data), indirect, special, punitive or other damages in relation to the Products including, without limitation: (a) any use or reliance on a Product by the person (including the form and content of errors in and/or omissions from any information contained in the Products); (b) any delay, interruption or other failure in the provision of a Product; or (c) any change in the form or content of a Product. All the foregoing limitations shall apply even if Licensor has been informed of the possibility of such damages.

        b) In no event will Licensor’s aggregate liability under any claims arising out of this EULA exceed the fees paid by you for the current Support Period, except where not permitted by applicable law, in which case Licensor’s liability shall be limited to the maximum extent allowed by such applicable law.

        c) Except for each party’s indemnification obligations or breach of Sections 7 or 10, neither party will be liable for lost profits or for special, indirect, incidental or consequential damages, regardless of the form of action, even if such party is advised of the possibility of such damages. The foregoing liability limitations shall apply to the maximum extent allowed by applicable law. To the extent the foregoing liability limitations or the warranty disclaimers of Section 11 are not allowed by applicable law, then the liability of Licensor, and the remedy of Licensee, shall be limited to: (i) the re-supply of any defective Product; or (ii) the refund of the license fees paid by you for the current Support Period for such defective Product.

        d) These limitations will apply to you even if the remedies fail of their essential purpose.

        <br /> 15. Dispute Resolution
        Any dispute arising out of or in connection with this Agreement, or in future agreements resulting therefrom, shall be exclusively resolved before the state courts located in Chennai, India.

        <br />  16. Severability clause
        If any term of this EULA is found to be unenforceable or contrary to law, it will be modified to the least extent necessary to make it enforceable, and the remaining portions of this EULA will remain in full force and effect.

        <br /> 17. No Waiver
        No waiver of any right under this EULA will be deemed effective unless contained in writing signed by a duly authorized representative of the party against whom the waiver is to be asserted, and no waiver of any past or present right arising from any breach or failure to perform will be deemed to be a waiver of any future rights arising out of this EULA.

        <br />18. Assignment
        Licensee may assign this EULA to succeeding parties in the case of a merger, acquisition or change of control; provided, however, that in each case, (a) Licensor is notified in writing within ninety (90) days of such assignment, (b) the assignee agrees to be bound by the terms and conditions contained in this EULA and (c) upon such assignment the assignee makes no further use of the Product(s) licensed under this EULA. Licensor may assign its rights and obligation under this EULA without consent of Licensee. Any permitted assignee shall be bound by the terms and conditions of this EULA.

        <br />  19. Revisions to this EULA
        Licensor may update, modify or amend (together, “Revise”) this EULA from time to time, including any referenced policies and other documents. If a revision meaningfully reduces your rights, Licensor will use reasonable efforts to notify you by, for example, sending an email to the billing or technical contact you designate in the applicable Order, posting on our blog, on the Freshworks Marketplace website (https://www.freshworks.com/apps/) or within the Licensor’s then currently published product documentation wiki. If Licensor revises this EULA during your term of your license or subscription, the revised version will be effective upon your next renewal of a License Term, Support Services, Hosted Services or Subscription Term, as applicable. In this case, if you object to any revisions, as your exclusive remedy, you may choose not to renew, including cancelling any terms set to auto-renew. With respect to No-Charge Products, accepting the revised EULA is required for you to continue using the No-Charge Products. You may be required to click through the updated EULA to show your acceptance. If you do not agree to the revised EULA after it becomes effective, you will no longer have a right to use No-Charge Products. For the avoidance of doubt, any Order is subject to the version of the EULA in effect at the time of the Order. You may not revise this EULA without Licensor’s written agreement (which may be withheld in Licensor’s complete discretion).


        <br />20. Entire Agreement
        This EULA constitutes the entire agreement between the parties with respect to its subject matter, and supersedes all prior agreements, proposals, negotiations, representations or communications relating to the subject matter. Both parties acknowledge that they have not been induced to enter into this EULA by any representations or promises not specifically stated herein. This EULA may not be modified or amended by you without Licensor’s written agreement (which may be withheld in Licensor’s complete discretion).

        In the event of a conflict between the terms of this EULA and the terms of any open source licenses applicable to the software, for the specific terms in conflict the terms of the open source licenses shall control with regard to the software, or part-thereof.

        <br />21. Contact Details
        For any questions regarding this EULA please contact Schnell Cloud at schnell.cloud@gmail.com.

      </code>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
);

export default SecondPage;
